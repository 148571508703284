@import "variables";
@import "buttons";


html,
body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
}

* {
    font-family: 'Poppins', 'arial', sans-serif;
    margin: 0;
    padding: 0;
}

.p-component {
    font-family: 'Poppins', 'arial', sans-serif;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// DESKTOP

h1 {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -2%;
}

h2 {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: 0;
    font-style: normal;
}

.h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
    font-style: normal;
    color: $contentA;
}


h3 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0;
}

h4 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0;
    font-style: normal;
    font-weight: 400;
}

.h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $contentB;
}

body {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
}

.caption1 {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
}

.caption2 {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0;
}

label {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0;
}

.label {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
    font-style: normal;
    color: $contentB;

    .label-link {
        text-decoration: underline;
        color: $accentD;
        cursor: pointer;
    }
}

// Separator line
.separator-line{
    width: 100%;
    height: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

// Mobile 
@media only screen and (max-width: $tablet) {

    h1 {
        font-size: 32px;
        line-height: 4px;
        letter-spacing: -2%;
    }

    h2 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0;
        font-style: normal;
    }


    h3 {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0;
    }

    h4 {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0;
        font-style: normal;
        font-weight: 400;
    }

    body {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0;
    }

}

